import React from "react";
import Footer from "components/Footer";

const ShippingPolicy = () => {
  return (
    <>
      <div className="content mt-5 py-5">
        <p className="text-center">
          <strong>
            <u>Shipping Policy</u>
          </strong>
        </p>
        <p className="text-center">At Sildenafil.com, we strive to provide fast and reliable shipping to ensure you receive your products promptly. Please review our shipping policy below for details on where we ship, our shipping methods, and associated charges.&nbsp;</p>
        <p>
          <strong>Shipping Destinations</strong>
        </p>
        <p>
          We currently ship to the following states in the United States:
        </p>
        <ol>
          <li>
            Alaska
          </li>
          <li>
            Arizona
          </li>
          <li>
            California
          </li>
          <li>
            Colorado
          </li>
          <li>
            Connecticut
          </li>
          <li>
            Delaware
          </li>
          <li>
            District of Columbia
          </li>
          <li>
            Florida
          </li>
          <li>
            Georgia
          </li>
          <li>
            Hawaii
          </li>
          <li>
            Idaho
          </li>
          <li>
            Illinois
          </li>
          <li>
            Indiana
          </li>
          <li>
            Iowa
          </li>
          <li>
            Maine
          </li>
          <li>
            Massachusetts
          </li>
          <li>
            Michigan
          </li>
          <li>
            Minnesota
          </li>
          <li>
            Missouri
          </li>
          <li>
            Missouri
          </li>
          <li>
            New Hampshire
          </li>
          <li>
            New Jersey
          </li>
          <li>
            New Mexico
          </li>
          <li>
            New York
          </li>
          <li>
            North Carolina
          </li>
          <li>
            North Dakota
          </li>
          <li>
            Ohio
          </li>
          <li>
            Pennsylvania
          </li>
          <li>
            Rhode Island
          </li>
          <li>
            South Carolina
          </li>
          <li>
            South Dakota
          </li>
          <li>
            Texas
          </li>
          <li>
            Utah
          </li>
          <li>
            Vermont
          </li>
          <li>
            Washington
          </li>
          <li>
            Wisconsin
          </li>
          <li>
            Wyoming
          </li>
        </ol>
        <p>
          <strong>
            Shipping Charges
          </strong>
        </p>
        <ol>
          <li>
            Free Shipping: Orders over $45 qualify for free shipping.
          </li>
          <li>
            USPS Express: $27.00
          </li>
          <li>
            USPS Priority Mail: $10.00
          </li>
        </ol>
        <p>
          <strong>
            Shipping Methods
          </strong>
        </p>
        <p>
          We use the United States Postal Service (USPS) for all shipments. You can select your preferred shipping method during checkout:
          <br />
        </p>
        <ol>
          <li>
            USPS Express
          </li>
          <li>
          USPS Priority Mail
          </li>
        </ol>
        <p>
          <strong>
            Handling Time
          </strong>
        </p>
        <p>
          We aim to process and ship all orders within 24 hours of receiving them. Orders placed on weekends or holidays will be processed the next business day.
        </p>
        <p>
          <strong>
            Shipping Time
          </strong>
        </p>
        <p>
          Once shipped, your order should arrive within 1 to 3 business days, depending on your selected shipping method and your location.
          <br />
        </p>
        <p>
          <strong>
            Contact Us
          </strong>
        </p>
        <p>
          If you have any questions or concerns regarding our shipping policy, please feel free to contact us at (844) 745-3362 or patientcare@sildenafil.com.
          <br />
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ShippingPolicy;
